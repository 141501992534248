import { Button, DraggableModal, Select, Space, Tooltip, Typography, Divider } from '@ui';
import { ColumnType, ModelEditorNodeSql } from '@modules/modelEditor/ModelEditorTypes';
import { useLazyRunSqlQuery } from '@modules/model/duck/modelApi';
import { useTableInfoQuery, useTablesQuery } from '@modules/viewer/duck/viewerApi';
import { selectViewerDataFormats, selectViewerGroupsMemo } from '@modules/viewer/duck/viewerSelectors';
import {
  isContainForbiddenWords,
  isContainSelect,
  isContainSpecialCharOrSpace,
} from '@modules/modelEditor/modals/utils';
import { selectStudyActiveUserRole } from '@modules/study/duck/studySelectors';
import { useStudyListQuery } from '@modules/study/duck/studyApi';
import { DataTable, DataTableProps, Loader, renderCellTypedValue } from '@components';
import { useSaveStage } from '@modules/modelEditor/modals/components/modelEditorModalsHooks';
import { SqlBuilder } from '@modules/modelEditor/components/sqlBuilder';
import { Tabs } from '@components/ui/tabs';
import { ExpressionVarsList } from '@modules/modelEditor/components/expressionBuilder/ExpressionVarsList';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { getFlatGroups } from '@modules/viewer/duck/viewerUtils';
import { selectModelEditorReadOnly } from '@modules/modelEditor/duck/modelEditorSelectors';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { extractType } from '@modules/modelEditor/components/schemaEditor/ModelEditorSchemaEditorUtils';
import CustomSqlHighlightMode from '@modules/modelEditor/components/customSql/Highlights';
import { Ace } from 'ace-builds/ace';
import { isCrossStudy } from '@shared/utils/common';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AceEditor from 'react-ace';
import { CloudServerOutlined, FilePptOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { RenderCellProps } from 'react-data-grid';

const PREVIEW_SIZE = 10;
export const STUDY_PLACEHOLDER = '<study_placeholder>';
export const ENV_PLACEHOLDER = '<env_placeholder>';

const initData = {
  sqlStatement: '',
  columns: [],
};

const ModelEditorModalsCustomSqlContent = ({ data, onClose, t }: ModelEditorModalsCustomSqlContentProps) => {
  const readOnly = useSelector(selectModelEditorReadOnly);
  const globalStudy = useSelector(selectGlobalStudy);
  const dataFormats = useSelector(selectViewerDataFormats);
  const studyActiveRole = useSelector(selectStudyActiveUserRole);
  const refEditor = React.createRef<AceEditor>();
  const viewerGroups = useSelector(selectViewerGroupsMemo(globalStudy!.id));
  const defaultStudy = { label: globalStudy?.name!, value: globalStudy?.id! };
  const [selectedStudy, setSelectedStudy] = useState(defaultStudy);
  const studiesQuery = useStudyListQuery({ roles: 1 });
  const { currentAppEnv, checkExternalSource } = useEnvironments();
  const tablesQuery = useTablesQuery(
    { study_id: selectedStudy.value!, role_id: studyActiveRole?.role_id },
    { skip: selectedStudy.value === undefined },
  );
  const [runSql, runSqlQuery] = useLazyRunSqlQuery();
  const { onSubmit } = useSaveStage(data?.nodeId, onClose);

  const [values, setValues] = useState<SqlProps>(initData);
  const [selectedTable, setSelectedTable] = useState<TablesSourceProps['rawData'] | null>(null);
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [customErrorMsg, setCustomErrorMsg] = useState('');
  const [latestSuccessfulQuery, setLatestSuccessfulQuery] = useState<string>('');

  const { currentData: previewResult, isFetching, isError } = runSqlQuery;
  const [db = '', tableId = ''] = selectedTable?.value.split('.') || '';
  const tableInfo = useTableInfoQuery({ tableName: tableId, fallbackCHDB: db }, { skip: !selectedTable });

  const getErrorMsq = (result: any) => {
    if (result.isError && result.error && 'data' in result.error) {
      const { status, data } = result.error;

      if (status === 403) {
        return t('sql.errors.forbiddenError');
      } else if (status === 500) {
        return t('sql.errors.serverError');
      } else {
        return data.devMsg ? `${data.userMsg}: ${data.devMsg}` : data.userMsg;
      }
    }
    return;
  };

  const queryTableFieldsErrorMessage = getErrorMsq(tableInfo);
  const queryRunSqlErrorMessage = getErrorMsq(runSqlQuery);

  useEffect(() => {
    if (data?.initData?.sql_statement) {
      setValues((prev) => ({
        ...prev,
        sqlStatement: data?.initData?.sql_statement || '',
        columns: data?.initData?.sql_schema || [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.initData?.sql_statement]);

  useEffect(() => {
    refEditor.current?.editor.focus();

    const customMode = new CustomSqlHighlightMode() as Ace.SyntaxMode;
    refEditor.current?.editor.getSession().setMode(customMode);
  }, [refEditor]);

  const sourceStudies = useMemo(() => {
    const studies = studiesQuery.data
      ? studiesQuery.data.map((study) => ({
          label: study.name,
          value: study.id,
          disabled: isCrossStudy(globalStudy?.id)
            ? false
            : study?.roles?.findIndex((role) => role === studyActiveRole!.name) === -1,
        }))
      : [];
    if (isCrossStudy(globalStudy?.id)) {
      studies?.unshift({ label: globalStudy?.name!, value: globalStudy?.id!, disabled: false });
    }

    return studies;
  }, [studiesQuery.data, studyActiveRole, globalStudy]);

  const sourceTablesGlobalStudy = useMemo(
    () =>
      getFlatGroups(viewerGroups)
        .flatMap(
          (el) =>
            el.tables?.map(({ id, name }) => ({
              name: name,
              description: el.name,
              rawData: { value: id, id: `${el.name}.${id}` },
            })) || [],
        )
        .filter((el) => el),
    [viewerGroups],
  );

  const sourceTables = useMemo(
    () =>
      tablesQuery.currentData
        ? tablesQuery.currentData
            .flatMap((el) =>
              el.tables?.map((item) => ({
                name: item.split('.')[1],
                description: el.name,
                rawData: { value: item, id: `${el.name}.${item}` },
              })),
            )
            .filter((el) => el)
        : [],
    [tablesQuery.currentData],
  );

  const sourceTableFields = useMemo(
    () =>
      tableInfo.currentData
        ? tableInfo.currentData?.columns.map((item) => ({
            name: item.name,
            description: item.type,
            rawData: { value: item.name },
          }))
        : [],
    [tableInfo.currentData],
  );

  const dataViewerTableSource = useMemo((): DataTableProps<any> => {
    const data = previewResult || { meta: [], data: [] };

    const columns =
      (data.meta &&
        data.meta?.map((item) => {
          const extractedItemType = extractType(item.type);

          return {
            key: item.name,
            name: item.name,
            renderCell: ({ row }: RenderCellProps<any>) =>
              renderCellTypedValue(row[item.name], extractedItemType, dataFormats),
          };
        })) ||
      [];

    const rows = data.data || [];

    return {
      columns,
      rows,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewResult]);

  const onChange = (val: string) => {
    setValues((prev) => ({ ...prev, sqlStatement: val, columns: [] }));
    if (latestSuccessfulQuery === val) {
      setIsDisabledSave(false);
    }
    setIsDisabledSave(true);
    setCustomErrorMsg('');
  };

  const clearStatement = (val: string) => val.replace(/;+$/g, '');

  const onRun = async () => {
    try {
      if (isContainForbiddenWords(values.sqlStatement)) {
        setCustomErrorMsg(t('sql.errors.containForbiddenWordsError'));
        return;
      }
      if (!isContainSelect(values.sqlStatement)) {
        setCustomErrorMsg(t('sql.errors.containSelectError'));
        return;
      }
      const externalSourceEnv = checkExternalSource({ targetSource: currentAppEnv?.env });
      const { meta } = await runSql({
        sql_statement: clearStatement(values.sqlStatement),
        size: PREVIEW_SIZE,
        sourceEnv: externalSourceEnv.isExternalSource === true ? externalSourceEnv.targetSourceEnv : undefined,
      }).unwrap();
      if (meta !== undefined && meta.length > 0) {
        setValues((prev) => ({
          ...prev,
          columns: meta,
          sqlStatement: clearStatement(prev.sqlStatement),
          isValid: true,
        }));
        setIsDisabledSave(false);
      }
      setLatestSuccessfulQuery(clearStatement(values.sqlStatement));
      setCustomErrorMsg('');
    } catch (e) {
      console.error('Error when run preview', e);
      setIsDisabledSave(true);
    }
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    const { sqlStatement = '', columns = [], isValid } = values;
    const sourcedColumns = columns.map((column) => ({
      ...column,
      source: previewResult?.lineage[column.name] ?? [],
    }));
    const updatedValues = {
      sql_statement: sqlStatement.trim(),
      preprocessed_sql: previewResult?.sql,
      sql_schema: sourcedColumns,
      isValid,
    };
    onSubmit(updatedValues);
  };

  const onClickTableHandler = (data: TablesSourceProps['rawData']) => setSelectedTable(data);

  const onClickStudyHandler = (value: { label: string; value: number }) => {
    setSelectedStudy(value);
    setSelectedTable(null);
  };

  const wrapValue = (value: string) => (isContainSpecialCharOrSpace(value) ? `"${value}"` : value);

  const addDataToEditor = (data: TablesSourceProps['rawData']) => {
    refEditor.current?.editor.insert(wrapValue(data?.value));
  };

  const onDragStart = (data: TablesSourceProps['rawData'], event: React.DragEvent) => {
    event.dataTransfer.setData('application/aceEditor/value', wrapValue(data.value));
  };

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      const value = event.dataTransfer.getData('application/aceEditor/value');
      if (typeof value === 'undefined' || !value) {
        return;
      }
      const editor = refEditor.current?.editor;
      if (editor) {
        editor.session.insert(editor.getCursorPosition(), value);
      }
    },
    [refEditor],
  );

  const onDragOver = useCallback(
    (event: any) => {
      event.preventDefault();
      const aceEditor = refEditor.current?.editor;
      if (aceEditor) {
        const { row, column } = aceEditor.renderer.pixelToScreenCoordinates(event.clientX, event.clientY);
        const cursorPosition = aceEditor.session.screenToDocumentPosition(row, column);
        aceEditor.moveCursorTo(cursorPosition.row, cursorPosition.column);
      }
    },
    [refEditor],
  );

  const filterOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const addPlaceholderToEditor = (placeholder: string) => () => {
    const editor = refEditor.current?.editor;
    if (editor) {
      const selection = editor.getSelectionRange();
      if (selection.isEmpty()) {
        editor.session.insert(editor.getCursorPosition(), placeholder);
      } else {
        editor.session.replace(selection, placeholder);
      }
    }
  };

  const renderLeftPanelWithSourceData = () => (
    <>
      <Space direction="horizontal" full css={cssStudyContainer}>
        <Select
          labelInValue
          style={{ width: '100%', flex: 1 }}
          options={sourceStudies}
          loading={studiesQuery.isLoading}
          onChange={onClickStudyHandler}
          value={selectedStudy}
          filterOption={filterOption}
          showSearch
          dropdownRender={(menu) =>
            !studiesQuery.isLoading ? (
              <>
                {menu}
                <Divider css={cssDivider} />
                <Typography.Text type="secondary">{t('sql.studyMenuTooltip')}</Typography.Text>
              </>
            ) : (
              <React.Fragment />
            )
          }
        />
        <Tooltip title={t('sql.studyPlaceholder')}>
          <Button icon={<FilePptOutlined />} onClick={addPlaceholderToEditor(STUDY_PLACEHOLDER)} />
        </Tooltip>
        <Tooltip title={t('sql.envPlaceholder')}>
          <Button icon={<CloudServerOutlined />} onClick={addPlaceholderToEditor(ENV_PLACEHOLDER)} />
        </Tooltip>
      </Space>
      <Divider css={cssDivider} />
      <ExpressionVarsList
        notDisableSingleClick
        disabled={readOnly}
        loading={tablesQuery.isFetching}
        dataSource={sourceTables}
        onClick={onClickTableHandler}
        onDoubleClick={(data) => {
          setSelectedTable(data);
          addDataToEditor(data);
        }}
        searchPlaceholder={t('sql.search.tables')}
        t={t}
        selectedItem={selectedTable}
        onDragStart={onDragStart}
        spaceSize="small"
      />
      <Divider css={cssDivider} />
      <ExpressionVarsList
        disabled={readOnly}
        dataSource={sourceTableFields}
        onDoubleClick={addDataToEditor}
        searchPlaceholder={t('sql.search.fields')}
        t={t}
        loading={tableInfo.isFetching}
        onDragStart={onDragStart}
        spaceSize="small"
      />
    </>
  );

  const renderPreview = () => (
    <Tabs
      css={cssTabs}
      defaultActiveKey="1"
      size="small"
      items={[
        {
          label: t('sql.result'),
          key: 'preview',
          children: (
            <>
              {isFetching && <Loader mode="absolute" />}
              {isError && <Typography.Text type="danger">{queryRunSqlErrorMessage}</Typography.Text>}
              {!previewResult && !isError && !isFetching && (
                <div css={cssEmptyBox}>
                  <Typography.Title type="secondary" level={4} children="Run a query to display results" />
                </div>
              )}
              {previewResult && (
                <>
                  <DataTable {...dataViewerTableSource} rowHeight={25} />
                  <Typography.Text type="secondary">
                    {t('sql.noticePreview', {
                      rowsCount: previewResult.data.length,
                      limit: PREVIEW_SIZE,
                    })}
                  </Typography.Text>
                </>
              )}
            </>
          ),
        },
      ]}
    />
  );

  const renderFooter = () =>
    readOnly ? (
      <Button children={t('close')} onClick={onCancel} />
    ) : (
      <Space>
        <Button children={t('cancel')} onClick={onCancel} />
        {isDisabledSave ? (
          <Tooltip title={t('sql.tooltipSaveBtn')}>
            <Button type="primary" children={t('save')} onClick={onSave} disabled={isDisabledSave} />
          </Tooltip>
        ) : (
          <Button type="primary" children={t('save')} onClick={onSave} disabled={isDisabledSave} />
        )}
      </Space>
    );

  return (
    <>
      <div css={cssContainerBody}>
        <div css={cssContainerTables}>
          {renderLeftPanelWithSourceData()}
          {tableInfo.isError && (
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <Typography.Text type="danger" title={queryTableFieldsErrorMessage}>
                {queryTableFieldsErrorMessage}
              </Typography.Text>
            </div>
          )}
        </div>
        <div css={cssContainerSql}>
          <SqlBuilder
            ref={refEditor}
            readOnly={readOnly}
            defaultValue={data?.initData?.sql_statement ?? ''}
            addDataToEditor={addDataToEditor}
            onChange={onChange}
            onDrop={onDrop}
            onDragOver={onDragOver}
            t={t}
          />
          <Space css={cssContainerBtn}>
            <Button type="primary" children={t('sql.run')} onClick={onRun} disabled={!values.sqlStatement} />
            <Typography.Text type="danger">{customErrorMsg}</Typography.Text>
          </Space>
          {renderPreview()}
        </div>
      </div>
      <Space full justify="end">
        {renderFooter()}
      </Space>
    </>
  );
};

export const ModelEditorModalsCustomSqlSettings = ({ open, data, onClose }: ModelEditorModalsCustomSqlProps) => {
  const { t } = useTranslation(['model']);

  const renderHeader = () => (
    <div css={cssTitle}>
      <div>{t('sql.title')}</div>
      <Tooltip placement="rightTop" title={t('sql.tooltipTitle')}>
        <InfoCircleOutlined width={8} height={8} />
      </Tooltip>
    </div>
  );

  return (
    <DraggableModal
      width={'80%'}
      style={{ minWidth: '400px' }}
      open={open}
      onCancel={onClose}
      title={renderHeader()}
      footer={null}
      destroyOnClose
    >
      {open && <ModelEditorModalsCustomSqlContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssContainerBody = (): CSSObject => ({
  display: 'flex',
  gap: '16px',
  marginTop: '16px',
});

const cssTitle = (): CSSObject => ({
  display: 'flex',
  gap: 8,
});

const cssContainerSql = (): CSSObject => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

const cssEmptyBox = (): CSSObject => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  textAlign: 'center',
});

const cssContainerBtn = (): CSSObject => ({
  padding: '8px',
});

const cssTabs = (): CSSObject => ({
  color: 'red',
  flex: 1,
  '&& .ant-tabs-content-holder': {
    justifyContent: 'center',
    display: 'flex',
  },
});

const cssContainerTables = (): CSSObject => ({
  width: '300px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const cssDivider = (): CSSObject => ({
  margin: '2px',
});

const cssStudyContainer = () => ({
  '& .ant-space-item:first-child': {
    flex: 1,
  },
});

interface ModelEditorModalsCustomSqlContentProps extends Pick<ModelEditorModalsCustomSqlProps, 'data' | 'onClose'> {
  t: TFunction;
}

interface TablesSourceProps {
  name: string;
  description?: string;
  rawData: { value: string; id?: string };
}

export interface ModelEditorModalsCustomSqlProps {
  open: boolean;
  data: {
    nodeId: string;
    initData?: ModelEditorNodeSql;
  };
  onClose: () => void;
}

interface SqlProps {
  sqlStatement: string;
  columns: ColumnType[];
  isValid?: boolean;
}
