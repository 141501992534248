import { useDispatch, useSelector } from 'react-redux';
import {
  AnalysisObjectSQLLabModalsImport,
  IAnalysisObjectSQLLabModalsImportProps,
} from './AnalysisObjectSQLLabModalsImport';
import { selectAnalysisObjectModals } from '../duck/analysisObjectSelectors';
import { analysisObjectActions } from '../duck/analysisObjectSlice';
import { EAnalysisObjectModalsType } from '../AnalysisObjectTypes';

export const AnalysisObjectModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAnalysisObjectModals);

  const closeModal = () => {
    dispatch(analysisObjectActions.dropModal());
  };

  return (
    <AnalysisObjectSQLLabModalsImport
      open={selectedModal === EAnalysisObjectModalsType.importSQLDataset}
      data={data as IAnalysisObjectSQLLabModalsImportProps['data']}
      onClose={closeModal}
    />
  );
};
