import {
  useModelQuery,
  useReviewModelMutation,
  useRunModelMutation,
  useSaveModelMutation,
  useConfigureModelMutation,
} from '@modules/model/duck/modelApi';
import { PageTemplateSimple } from '@components';
import { Button, Dropdown, notification, Space, Tag } from '@ui';
import routes from '@routes';
import { RootState } from '@store';
import { ModelEditorSandbox } from '@modules/modelEditor/components';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { ModelEditorModalsController } from '@modules/modelEditor/modals';
import {
  exportModelEditorData,
  getFullTableName,
  sanitizeModelEditorData,
} from '@modules/modelEditor/duck/modelEditorUtils';
import { validateNodes } from '@modules/modelEditor/components/builder/Validator';
import { compileSparkInstruction } from '@modules/modelEditor/duck/modelEditorSparkIntegration';
import { modelActions } from '@modules/model/duck/modelSlice';
import { ModelModalsController, ModelModalsType } from '@modules/model/modals';
import { useLazyTablesExistQuery, useLazyTablesInSqlExistQuery } from '@modules/viewer/duck/viewerApi';
import {
  ModelEditor,
  ModelEditorNodeDomain,
  ModelEditorNodeSql,
  ModelEditorNodeType,
} from '@modules/modelEditor/ModelEditorTypes';
import { QueryErrorType } from '@shared/utils/Error';
import { getReviewSourceTableName } from '@modules/viewer/duck/viewerUtils';
import { SupportedEnvs } from '@app/AppTypes';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { isCrossStudy } from '@shared/utils/common';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import { ReactFlowProvider } from 'reactflow';
import { useCallback, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { TFunction } from 'i18next';

const RenderExtraActions = (props: RenderExtraActionsProps) => {
  return (
    <Space wrap>
      <Dropdown.Button
        type="primary"
        disabled={props.disabled}
        loading={props.isLoading}
        menu={{
          items: [
            {
              label: props.t('builder.review'),
              key: 'review',
              onClick: props.onReview,
            },
            props.canDataModelRun && {
              label: props.t('builder.runAndSave'),
              key: 'save_run',
              onClick: props.onRunModel,
            },
            {
              label: props.t('saveExit'),
              key: 'save_exit',
              onClick: props.onSaveExit,
            },
          ].filter((item) => typeof item === 'object') as Array<{
            label: string;
            key: string;
            onClick: () => void;
          }>,
        }}
        onClick={props.onSave}
        children={props.t('save')}
      />
      {props.canDataModelRun && (
        <Button children={props.t('rootTable.actionMenu.viewLog')} onClick={props.onViewLogs} />
      )}
      <Button children={props.t('cancel')} onClick={props.onCancel} />
    </Space>
  );
};

const RenderReadOnlyExtraActions = (props: Omit<RenderExtraActionsProps, 'onSaveExit' | 'onSave'>) => {
  return (
    <Space wrap>
      <Button
        type="primary"
        disabled={props.disabled}
        loading={props.isLoading}
        onClick={props.onReview}
        children={props.t('builder.review')}
      />
      <Button children={props.t('rootTable.actionMenu.viewLog')} onClick={props.onViewLogs} />
      <Button children={props.t('cancel')} onClick={props.onCancel} />
    </Space>
  );
};

interface RenderExtraActionsProps {
  t: TFunction;
  disabled?: boolean;
  isLoading?: boolean;
  canDataModelRun?: boolean;
  onReview: () => void;
  onRunModel: () => void;
  onCancel: () => void;
  onViewLogs: () => void;
  onSaveExit: () => void;
  onSave: () => void;
}

export const EditorModelPane = ({
  modelId,
  isExternalModel,
  modelSourceEnv,
  modelSourceEnvData,
  currentAppEnv,
}: EditorModelPaneProps) => {
  const store = useStore<RootState>();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { studyId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['model']);
  const modelQuery = useModelQuery({ modelId, sourceEnv: isExternalModel === true ? modelSourceEnv : undefined });
  const {
    userPermissions: { canDataModelRun },
  } = useStudyPermissions();
  const {
    appPermissions: { canCrossDataModelRun },
  } = useAppPermissions();
  const [configureModel, configureModelQuery] = useConfigureModelMutation();
  const [runModel, runModelQuery] = useRunModelMutation();
  const [reviewModel, reviewModelQuery] = useReviewModelMutation();
  const [getTablesExistInfo, getTablesExistResult] = useLazyTablesExistQuery();
  const [getTablesInSqlExistInfo, getTablesInSqlExistResult] = useLazyTablesInSqlExistQuery();
  const [schema, setSchema] = useState<ModelEditor>();

  const getTablesInfo = useCallback((tables: string[]) => getTablesExistInfo(tables, true), [getTablesExistInfo]);

  useEffect(() => {
    if (modelQuery.data?.name !== store.getState().modelEditor.modelName) {
      store.dispatch(modelEditorActions.reset());
      store.dispatch(modelActions.setDataViewer({ tableKey: null, tableName: null }));
    }
  }, [modelQuery.data?.name, store]);

  useEffect(() => {
    const tablesToCheck = modelQuery?.data?.schema
      .filter((node) => node.type === ModelEditorNodeType.domain)
      .map((domain) => (domain.data as ModelEditorNodeDomain).tableName ?? '')
      .filter(Boolean);

    const sqlToCheck = modelQuery?.data?.schema
      .filter((node) => node.type === ModelEditorNodeType.sql)
      .map(
        (node) =>
          ((node.data as ModelEditorNodeSql).preprocessed_sql || (node.data as ModelEditorNodeSql).sql_statement) ?? '',
      )
      .filter(Boolean);

    if (modelQuery?.data?.schema) {
      setSchema(modelQuery?.data?.schema);
    }

    const checkTablesAndSql = async () => {
      try {
        let updatedSchema = modelQuery?.data?.schema || [];

        if (tablesToCheck && tablesToCheck.length > 0) {
          const tablesInfo = await getTablesInfo(tablesToCheck);
          updatedSchema = updatedSchema.map((node) => {
            if (node.type === ModelEditorNodeType.domain) {
              const domainData = node.data as ModelEditorNodeDomain;
              const tableName = domainData.tableName ?? '';
              return {
                ...node,
                data: {
                  ...node.data,
                  exists: !!tablesInfo.data![tableName],
                },
              };
            }
            return node;
          });
        }

        if (sqlToCheck && sqlToCheck.length > 0) {
          const sqlInfo = await getTablesInSqlExistInfo(sqlToCheck);
          updatedSchema = updatedSchema.map((node) => {
            if (node.type === ModelEditorNodeType.sql) {
              const sqlData = node.data as ModelEditorNodeSql;
              const sql = (sqlData.preprocessed_sql || sqlData.sql_statement) ?? '';
              return {
                ...node,
                data: {
                  ...node.data,
                  isValid: !!sqlInfo.data![sql],
                },
              };
            }
            return node;
          });
        }

        setSchema(updatedSchema);
      } catch (e) {
        console.error("Error while checking tables and SQL's", e);
        setSchema(modelQuery?.data?.schema);
      }
    };

    checkTablesAndSql();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelQuery?.data?.schema]);

  const validateAndClearModelEditorData = async () => {
    const state = store.getState();

    const modelEditorData = exportModelEditorData(state.modelEditor);

    const modelEditorDataCleared = modelEditorData.map((node) => {
      const { isCheckingNeeded = false, ...clearData } = { ...node.data };
      return {
        ...node,
        data: {
          isCheckingNeeded,
          ...clearData,
        },
      };
    });

    const validationErrors = validateNodes(modelEditorDataCleared).map((error) => ({
      ...error,
      message: t(error.message, error.params ?? {}),
    }));

    store.dispatch(modelEditorActions.setErrors(validationErrors));
    if (validationErrors.length !== 0) {
      api.error({
        message: <Space direction="vertical" children={uniq(validationErrors.map((item) => item.message))} />,
      });
      throw new Error(validationErrors.map((error) => error.message).join('; '));
    }
    return modelEditorDataCleared;
  };

  const saveModelEditor = async () => {
    const modelEditorDataCleared = await validateAndClearModelEditorData();

    await configureModel({
      schema: sanitizeModelEditorData(modelEditorDataCleared),
      spark_schema: compileSparkInstruction(
        modelEditorDataCleared,
        modelQuery.data!.name,
        store.getState().study.fallbackCHDB,
        store.getState().auth.user!.cluster,
      ),
      id: modelQuery.data!.id,
    });
  };

  const onSave = async () => {
    try {
      await saveModelEditor();
    } catch (e) {
      console.error(e);
    }
  };

  const onSaveExit = async () => {
    try {
      await saveModelEditor();

      store.dispatch(modelEditorActions.reset());
      onCancel();
    } catch (e) {
      console.error(e);
    }
  };

  const onRunModel = async () => {
    try {
      await saveModelEditor();

      await runModel(modelId).unwrap();
      dispatch(
        modelActions.setDataViewer({
          tableKey: getFullTableName(modelQuery.data!.name, store.getState().study.fallbackCHDB),
          tableName: modelQuery.data!.name,
        }),
      );
      notification.info({ message: t('builder.runNotification') });
    } catch (e) {
      console.error(e);
      notification.error({
        message: (e as QueryErrorType).data.userMsg,
        description: (e as QueryErrorType).data.devMsg,
        duration: 0,
      });
    }
  };

  const onReviewModel = async () => {
    if (isExternalModel === undefined) {
      return;
    }

    try {
      const modelEditorDataCleared = await validateAndClearModelEditorData();

      await reviewModel({
        configuration: {
          name: modelQuery.data?.name,
          schema: sanitizeModelEditorData(modelEditorDataCleared),
          spark_schema: compileSparkInstruction(
            modelEditorDataCleared,
            modelQuery.data!.name,
            store.getState().study.fallbackCHDB,
            store.getState().auth.user!.cluster,
          ),
        },
        source: modelSourceEnvData?.label!,
        source_env: currentAppEnv!,
      }).unwrap();

      dispatch(
        modelActions.setDataViewer({
          tableKey: getFullTableName(
            getReviewSourceTableName(modelQuery.data!.name, modelSourceEnvData?.label!),
            store.getState().study.fallbackCHDB,
          ),
          tableName: modelQuery.data!.name,
          sourceEnv: currentAppEnv,
          isReview: true,
        }),
      );

      notification.info({ message: t('builder.reviewNotification') });
    } catch (e) {
      notification.error({
        message: (e as QueryErrorType).data.userMsg,
        description: (e as QueryErrorType).data.devMsg,
        duration: 0,
      });
    }
  };

  const onViewLogs = () =>
    store.dispatch(
      modelActions.pushModal({
        type: ModelModalsType.openLogs,
        data: {
          modelId: modelQuery.data!.id,
          tableId: getReviewSourceTableName(modelQuery.data!.name, modelSourceEnvData?.label!),
          tableName: modelQuery.data?.name!,
        },
      }),
    );

  const onCancel = () => {
    if (studyId) {
      if (isCrossStudy(parseInt(studyId ?? '-1'))) {
        navigate(routes.crossStudy.models.root.resolver({ studyId }));
      } else {
        navigate(routes.study.models.root.resolver({ studyId }));
      }
    } else {
      navigate(routes.app.root.resolver());
    }
  };

  const schemaIsLoading = !schema || !modelQuery.data || modelQuery.isLoading;
  const isValidating = getTablesExistResult.isLoading || getTablesInSqlExistResult.isLoading;

  return (
    <>
      <PageTemplateSimple
        content={{
          isLoading: schemaIsLoading,
          errorText: t('loadingError'),
          error: modelQuery.error,
        }}
        title={{
          hidden: schemaIsLoading,
          pageTitle: t('pageTitleConfig'),
          children: (
            <Space>
              {modelQuery.data?.name}
              {isExternalModel && <Tag type="source" text={modelSourceEnvData?.label} upperCaseText />}
            </Space>
          ),
          extra: isExternalModel ? (
            <RenderReadOnlyExtraActions
              t={t}
              disabled={isValidating || schemaIsLoading}
              isLoading={runModelQuery.isLoading || configureModelQuery.isLoading}
              canDataModelRun={canDataModelRun || (canCrossDataModelRun && isCrossStudy(parseInt(studyId ?? '-1')))}
              onCancel={onCancel}
              onRunModel={onRunModel}
              onViewLogs={onViewLogs}
              onReview={onReviewModel}
            />
          ) : (
            <RenderExtraActions
              t={t}
              disabled={isValidating || schemaIsLoading}
              isLoading={runModelQuery.isLoading || configureModelQuery.isLoading}
              canDataModelRun={canDataModelRun || (canCrossDataModelRun && isCrossStudy(parseInt(studyId ?? '-1')))}
              onCancel={onCancel}
              onRunModel={onRunModel}
              onSave={onSave}
              onSaveExit={onSaveExit}
              onViewLogs={onViewLogs}
              onReview={onReviewModel}
            />
          ),
        }}
      >
        {!schemaIsLoading && (
          <ReactFlowProvider>
            <ModelEditorSandbox
              initReadOnly={!!isExternalModel}
              canBeUnlocked={!isExternalModel}
              initData={schema}
              modelName={modelQuery.data!.name}
              loading={isValidating}
            />
          </ReactFlowProvider>
        )}
      </PageTemplateSimple>
      {contextHolder}
      <ModelEditorModalsController />
      <ModelModalsController />
    </>
  );
};

interface EditorModelPaneProps {
  modelId: number;
  currentAppEnv?: string;
  isExternalModel?: boolean;
  modelSourceEnv?: string;
  modelSourceEnvData?: SupportedEnvs[0];
}
