import { Typography, Table, Link, notification } from '@ui';
import { dateToString } from '@shared/utils/Date';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getActionTitle, getKindTitle } from '@modules/library/audit/duck/libraryAuditHelpers';
import { ActorAndInfo } from '@components';
import { LibraryAuditModalsType } from '@modules/library/audit/modals/LibraryAuditModalsConstants';
import { libraryAuditActions } from '@modules/library/audit/duck/libraryAuditSlice';
import { useLazySimpleQuerySubscription } from '@config/appApi';
import { libraryRoutes } from '@routes/library';
import { LibraryApiRoutes } from '@modules/library/root/duck/libraryApi';
import { LibraryModelApiRoutes } from '@modules/library/model/duck/libraryModelApi';
import { LibraryCdrApiRoutes } from '@modules/library/cdr/duck/libraryCdrApi';
import { LibrarySqlQueryApiRoutes } from '@modules/library/sqlQuery/duck/librarySqlQueryApi';
import { LibraryNotebookApiRoutes } from '@modules/library/notebook/duck/libraryNotebookApi';
import { LibraryObjectApiRoutes } from '@modules/library/libraryObject/duck/libraryObjectApi';
import { TableColumnsType, TableProps } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  LibraryAuditLogItem,
  LibraryAuditLogItemAction,
  LibraryAuditLogItemKind,
  LibraryAuditLogListResponse,
} from '../LibraryAuditTypes';

const getEntityLink = (kind: LibraryAuditLogItemKind, id: number | string, libraryId: number) => {
  switch (kind) {
    case 'MODEL':
      return {
        href: libraryRoutes.models.root.resolver({ libraryId }),
        api: LibraryModelApiRoutes.list,
      };
    case 'GL':
      return {
        href: libraryRoutes.view.resolver({ libraryId }),
        api: LibraryApiRoutes.list,
      };
    case 'REPORT':
      return {
        href: libraryRoutes.cdr.root.resolver({ libraryId }),
        api: LibraryCdrApiRoutes.list,
      };
    case 'SQL_QUERY':
      return {
        href: libraryRoutes.sqlQuery.root.resolver({ libraryId }),
        api: LibrarySqlQueryApiRoutes.list,
      };
    case 'SQLLAB':
      return {
        href: libraryRoutes.sqlLab.root.resolver({ libraryId }),
        api: LibraryObjectApiRoutes.sqlLabList,
      };
    case 'NOTEBOOK':
      return {
        href: libraryRoutes.notebook.root.resolver({ libraryId }),
        api: LibraryNotebookApiRoutes.list,
      };
    default:
      return {
        href: '#',
      };
  }
};

export const LibraryAuditLogList = ({
  disableKindSort,
  data,
  loading,
  pagination,
  libraryId,
  onChange,
}: LibraryAuditLogListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triggerSimpleQuery] = useLazySimpleQuerySubscription();
  const navigate = useNavigate();
  const { getColumnSearchProps, locale } = useColumnSearch<LibraryAuditLogItem>(t('audit.table.filter.noData'));

  const openDiffModal = (data: LibraryAuditLogItem) => {
    dispatch(libraryAuditActions.pushModal({ type: LibraryAuditModalsType.showDetails, data }));
  };

  const columns: TableColumnsType<LibraryAuditLogItem> = useMemo(
    () => [
      {
        width: 150,
        title: t('audit.table.column.date'),
        dataIndex: 'action_date',
        sorter: (a, b) => 0,
        sortDirections: ['ascend'],
        render: (action_date: number) => (
          <Typography.Text type="secondary">{dateToString(action_date)}</Typography.Text>
        ),
      },
      {
        width: 220,
        title: t('audit.table.column.username'),
        dataIndex: 'user_name',
        ...getColumnSearchProps('user_name'),
        onFilter: undefined,
        render: (_, record) => <ActorAndInfo info={record.ip} actor={record.user_name} />,
      },
      {
        width: 150,
        title: t('audit.table.column.action'),
        dataIndex: 'action',
        filters: [
          {
            text: t('audit.table.created'),
            value: 'CREATED',
          },
          {
            text: t('audit.table.updated'),
            value: 'UPDATED',
          },
          {
            text: t('audit.table.versionCreated'),
            value: 'VERSION_CREATED',
          },
          {
            text: t('audit.table.versionRemoved'),
            value: 'VERSION_REMOVED',
          },
          {
            text: t('audit.table.deleted'),
            value: 'DELETED',
          },
        ],
        render: (action: LibraryAuditLogItemAction) => getActionTitle(t, action),
      },
      {
        title: t('audit.table.column.kind'),
        dataIndex: 'kind',
        render: (kind: LibraryAuditLogItemKind) => getKindTitle(t, kind),
        ...(disableKindSort
          ? {}
          : {
              filters: [
                {
                  text: t('audit.table.library'),
                  value: 'GL',
                },
                {
                  text: t('audit.table.model'),
                  value: 'MODEL',
                },
                {
                  text: t('audit.table.report'),
                  value: 'REPORT',
                },
                {
                  text: t('audit.table.sqllab'),
                  value: 'SQLLAB',
                },
                {
                  text: t('audit.table.sqlQuery'),
                  value: 'SQL_QUERY',
                },
                {
                  text: t('audit.table.notebook'),
                  value: 'NOTEBOOK',
                },
              ],
            }),
      },
      // libraryRoutes.view.resolver({ libraryId })
      {
        title: t('audit.table.column.name'),
        dataIndex: 'entity_name',
        ...getColumnSearchProps('entity_name'),
        onFilter: undefined,
        render: (entityName: string, record) => (
          <Typography.Text strong ellipsis>
            <Link
              title={entityName}
              to={getEntityLink(record.kind, record.entity_id, libraryId).href}
              children={entityName}
              style={{ fontWeight: 'inherit' }}
              disabled={record.deleted}
              onClick={(event) => {
                const entityLink = getEntityLink(record.kind, record.entity_id, libraryId);
                if (entityLink.api) {
                  event.preventDefault();
                  triggerSimpleQuery({ url: entityLink.api })
                    .unwrap()
                    .then(() => {
                      navigate(entityLink.href);
                    })
                    .catch(() => {
                      notification.error({
                        message: t('notification.resourceUnavailable'),
                      });
                    });
                }
              }}
            />
          </Typography.Text>
        ),
      },
      {
        width: 110,
        render: (_, record) => (
          <Typography.Link children={t('audit.table.diff')} onClick={() => openDiffModal(record)} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface LibraryAuditLogListProps {
  libraryId: number;
  data?: LibraryAuditLogListResponse;
  pagination?: TableProps<LibraryAuditLogItem>['pagination'];
  onChange?: TableProps<LibraryAuditLogItem>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
}
