import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo, InformationModal } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { tableListToOptions, tableNamesToOptions } from '@shared/utils/Table';
import { Button, Popconfirm, Space, Table } from '@ui';
import { SupportedEnvs } from '@app/AppTypes';
import { Scope } from '@modules/dataset/DatasetTypes';
import { DatasetScopeList, DatasetScopeListScope, renderInfoModalContent } from '@modules/dataset/components';
import { ISQLLabObject, ISQLLabObjectListResponse } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { useMemo, useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { DeleteOutlined } from '@ant-design/icons';

export const SQLLabObjectList = ({
  data,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  onDelete,
  t,
}: SQLLabObjectListProps) => {
  const [studiesDetailsModal, setStudiesDetailsModal] = useState<DatasetScopeListScope>();
  const { getColumnSearchProps, locale } = useColumnSearch<ISQLLabObject>();

  const storeListOption = tableNamesToOptions(data?.items, 'data_store');
  const sourceListOption = tableListToOptions(data?.items?.map((el) => ({ name: el.source!, id: el.source })));

  const columns = useMemo(
    () =>
      (
        [
          {
            title: t('sqlLab.rootTable.name'),
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            onFilter: undefined,
          },
          {
            title: t('sqlLab.rootTable.scope'),
            dataIndex: 'object_scope',
            render: (object_scope: Scope) => (
              <DatasetScopeList currentStudyName="-" onMoreBtnClick={setStudiesDetailsModal} scope={object_scope} />
            ),
          },
          {
            title: t('sqlLab.rootTable.libraryVersion'),
            dataIndex: 'version',
            render: (_, record) =>
              Array.isArray(record.library_id || record.versions) && (record.library_id || record.versions)?.length
                ? record.version
                : '-',
          },
          {
            title: t('sqlLab.rootTable.source'),
            dataIndex: 'source',
            filters: sourceListOption,
            onFilter: undefined,
            render: (_, record) =>
              record.source && (
                <ActorAndInfo
                  info={supportedEnvs[record.env]?.label || t('na')}
                  actor={record.source}
                  actorSize={'sm'}
                />
              ),
          },
          {
            title: t('sqlLab.rootTable.created'),
            dataIndex: 'linked_at',
            sorter: () => 0,
            sortDirections: ['ascend'],
            render: (_, record) => (
              <ActorAndInfo info={dateToString(record.linked_at || record.created_at)} actor={record.created_by} />
            ),
          },
          !!onDelete && {
            width: 80,
            title: t('delete'),
            dataIndex: 'actionDelete',
            align: 'center',
            render: (_, record) => (
              <Space>
                <Popconfirm
                  title={t('sqlLab.confirmation.title')}
                  description={t('sqlLab.confirmation.description')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={() => onDelete({ id: record.version_id!, name: record.name })}
                >
                  <Button icon={<DeleteOutlined />} loading={loadingDelete} />
                </Popconfirm>
              </Space>
            ),
          },
        ] as TableColumnsType<ISQLLabObject>
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeListOption, sourceListOption],
  );

  return (
    <>
      <Table
        bordered
        locale={locale}
        columns={columns}
        dataSource={data?.items}
        loading={loading}
        rowKey={(item) => item.id}
        onChange={onChange}
        tableLayout="fixed"
        scroll={{ x: 900 }}
        pagination={pagination}
      />
      {studiesDetailsModal && (
        <InformationModal
          message={renderInfoModalContent(studiesDetailsModal)}
          onClose={() => setStudiesDetailsModal(null)}
          width="500px"
          autoHeightMin={50}
        />
      )}
    </>
  );
};

export interface SQLLabObjectListProps {
  data?: ISQLLabObjectListResponse;
  pagination?: TableProps<ISQLLabObject>['pagination'];
  onChange?: TableProps<ISQLLabObject>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDelete?: (data: { id: number; name: string }) => void;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
