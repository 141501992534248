import {
  ILibraryNotebook,
  ILibraryNotebookResponse,
  TLibraryNotebookDeleteProps,
} from '@modules/library/notebook/LibraryNotebookTypes';
import { Button, Popconfirm, Space, Table } from '@ui';
import { NOTEBOOKS_TYPES } from '@modules/library/notebook/duck/libraryNotebookConstants';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { capitalizeFirstLetter } from '@shared/utils/Form';
import { getTableListOption } from '@shared/utils/Table';
import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo } from '@components';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { TableColumnsType, TableProps } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const LibraryNotebookList = ({
  data,
  loading,
  loadingDelete,
  pagination,
  supportedEnvs,
  onChange,
  onDelete,
  t,
}: NotebookListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<any>();

  const columns: TableColumnsType<ILibraryNotebook> = useMemo(
    () =>
      [
        {
          title: t('rootTable.name'),
          dataIndex: 'name',
          ...getColumnSearchProps('name'),
          onFilter: undefined,
        },
        {
          title: t('rootTable.type'),
          dataIndex: 'object_type',
          filters: getTableListOption(NOTEBOOKS_TYPES, true),
          onFilter: undefined,
          render: (val: string) => capitalizeFirstLetter(val),
        },
        {
          width: 100,
          title: t('rootTable.libraryVersion'),
          dataIndex: 'version',
        },
        {
          title: t('rootTable.source'),
          dataIndex: 'source',
          render: (_: any, record: ILibraryNotebook) => (
            <ActorAndInfo
              info={supportedEnvs[record.env]?.label || t('na')}
              actor={record.source || t('crossStudy')}
              actorSize={'sm'}
            />
          ),
        },
        {
          title: t('rootTable.created'),
          dataIndex: 'created_at',
          sorter: () => 0,
          sortDirections: ['ascend'],
          render: (createdAt: number, record: ILibraryNotebook) => (
            <ActorAndInfo info={dateToString(createdAt)} actor={record.created_by} />
          ),
        },
        onDelete && {
          width: 80,
          title: t('delete'),
          dataIndex: 'actionDelete',
          align: 'center',
          render: (_: any, record: ILibraryNotebook) => (
            <Space>
              <Popconfirm
                title={t('confirmation.title')}
                description={t('confirmation.description')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => onDelete({ id: record.id, name: record.name })}
              >
                <Button icon={<DeleteOutlined />} loading={loadingDelete} />
              </Popconfirm>
            </Space>
          ),
        },
      ].filter(Boolean) as TableColumnsType<ILibraryNotebook>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete, onDelete],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface NotebookListProps {
  libraryId: number;
  data?: ILibraryNotebookResponse;
  pagination?: TableProps<ILibraryNotebookResponse['items'][0]>['pagination'];
  onChange?: TableProps<ILibraryNotebookResponse['items'][0]>['onChange'];
  onDelete?: (val: TLibraryNotebookDeleteProps) => void;
  loading?: boolean;
  loadingDelete?: boolean;
  pageSize?: number;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
