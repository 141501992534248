import { ILibraryObjectModalImportSqlLabProps } from './modals/LibraryObjectModalImportSqlLab';

export enum ELibraryObjectModalsType {
  'copySqlLab' = 'copySqlLab',
}

export type TLibrarySqlLabModalsPushModalPayload = {
  type: ELibraryObjectModalsType.copySqlLab;
  data?: ILibraryObjectModalImportSqlLabProps['data'];
};

export type TLibrarySqlLabModalsState = {
  selectedModal: ELibraryObjectModalsType | null;
  data?: ILibraryObjectModalImportSqlLabProps['data'];
};
