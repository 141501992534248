import { appApi } from '@config/appApi';
import { IDnAObjectListQueryParams, TDnAObjectFilterResponse } from '@modules/dnaObject/DnAObjectTypes';
import { ISQLLabObjectListResponse, ISQLLabObjectQuery } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { TagDescription } from '@reduxjs/toolkit/query/react';
import { ANALYSIS_OBJECT_TAG_DESCRIPTION } from './analysisObjectConstants';

export const AnalysisObjectApiRoutes = {
  sqlLabList: '/api/analysis/sql-lab',
  sqlLabItem: (analysisObjectId: number) => `/api/analysis/sql-lab/${analysisObjectId}`,
  sqlLabFilters: '/api/analysis/sql-lab/filters',
};

const AnalysisObjectInvalidations: {
  SQL_LAB_LIST: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_LIST>;
  SQL_LAB_ITEM: (analysisObjectId: number) => TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_ITEM>;
  SQL_LAB_FILTERS: TagDescription<ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_FILTERS>;
} = {
  SQL_LAB_LIST: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_LIST, id: 'LIST' },
  SQL_LAB_ITEM: (analysisObjectId: number) => ({
    type: ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_ITEM,
    analysisObjectId,
  }),
  SQL_LAB_FILTERS: { type: ANALYSIS_OBJECT_TAG_DESCRIPTION.SQL_LAB_FILTERS, id: 'FILTERS' },
};

export const AnalysisObjectSQLDatasetApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    analysisObjectSQLDatasetList: builder.query<ISQLLabObjectListResponse, IDnAObjectListQueryParams | void>({
      providesTags: [AnalysisObjectInvalidations.SQL_LAB_LIST],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.sqlLabList,
      }),
    }),
    analysisObjectSQLDatasetFilters: builder.query<TDnAObjectFilterResponse, void>({
      providesTags: [AnalysisObjectInvalidations.SQL_LAB_FILTERS],
      query: (params) => ({
        params,
        url: AnalysisObjectApiRoutes.sqlLabFilters,
      }),
    }),
    deleteAnalysisObjectSQLDataset: builder.mutation<void, number>({
      invalidatesTags: (request, error, analysisObjectId) => [
        AnalysisObjectInvalidations.SQL_LAB_LIST,
        AnalysisObjectInvalidations.SQL_LAB_ITEM(analysisObjectId),
      ],
      query: (analysisObjectId) => ({
        method: 'DELETE',
        url: AnalysisObjectApiRoutes.sqlLabItem(analysisObjectId),
      }),
    }),
    importAnalysisObjectSQLDataset: builder.mutation<void, ISQLLabObjectQuery>({
      invalidatesTags: [AnalysisObjectInvalidations.SQL_LAB_LIST],
      query: ({ data, source }) => ({
        params: { source: source?.toLowerCase() },
        data,
        method: 'POST',
        url: AnalysisObjectApiRoutes.sqlLabList,
      }),
    }),
  }),
});

export const {
  useAnalysisObjectSQLDatasetListQuery,
  useAnalysisObjectSQLDatasetFiltersQuery,
  useDeleteAnalysisObjectSQLDatasetMutation,
  useImportAnalysisObjectSQLDatasetMutation,
} = AnalysisObjectSQLDatasetApi;
