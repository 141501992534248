import { IDataset } from '@modules/dataset/DatasetTypes';
import { prepareLibraryObject } from '@modules/library/root/duck/libraryUtils';
import { IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { IPrepareSqlLabTableData, ISQLLabObject, ISqlLabObjectConfiguration } from '../SQLLabObjectTypes';

export function prepareSqlLabTableData(props: IPrepareSQLLabTableDataFromStudy): IPrepareSqlLabTableData<IDataset>;
export function prepareSqlLabTableData(
  props: IPrepareSQLLabTableDataFromDnAObject,
): IPrepareSqlLabTableData<ISQLLabObject>;
export function prepareSqlLabTableData(props: IPrepareSQLLabTableDataFromStudy | IPrepareSQLLabTableDataFromDnAObject) {
  if (!Array.isArray(props.data)) return [];

  if ('studyId' in props) {
    const { data, studyId, protocolId } = props;

    const filteredDatasets = data.filter((el) => {
      if (Array.isArray(el?.scope) && el?.scope.length > 1) {
        return el?.scope.some((scopeItem) => {
          if ('study_id' in scopeItem) {
            return scopeItem.study_id === studyId;
          } else if ('protocol_id' in scopeItem) {
            return scopeItem.protocol_id === protocolId;
          }
          return false;
        });
      }
      return true;
    });

    return filteredDatasets.map((item) => ({
      id: item.id,
      name: item.name,
      scope: item.scope,
      rawData: item,
    }));
  }

  return props.data.map((item) => ({
    id: item.id,
    name: item.name,
    scope: item.object_scope,
    libraryVersion: Array.isArray(item.versions) ? item.version : undefined,
    rawData: item,
  })) as IPrepareSqlLabTableData<ISQLLabObject>;
}

export const prepareSQLLabObjectForImport = (
  values: IDataset[] | ISQLLabObject[],
  systemInfo: IBaseLibraryEntitySystemInfo,
) => {
  return prepareLibraryObject<ISqlLabObjectConfiguration>(
    values.map((item) => {
      if ('object_scope' in item) {
        return { ...item, configuration: item.configuration! };
      }
      return {
        name: item.name,
        configuration: { name: item.name, scope: item.scope, query: item.query },
      };
    }),
    systemInfo,
  ).map((item) => ({ ...item, source_id: null, description: '' }));
};

interface IPrepareSQLLabTableDataFromStudy {
  data: IDataset[];
  studyId: number;
  protocolId: string;
}

interface IPrepareSQLLabTableDataFromDnAObject {
  data: ISQLLabObject[];
}
