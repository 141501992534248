import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getListOption, tableListToOptions } from '@shared/utils/Table';
import { Button, Popconfirm, Space, Table } from '@ui';
import { EPscType, EPscTypeNames, TLibraryPscDeleteProps } from '@modules/library/psc/PscTypes';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { SupportedEnvs } from '@app/AppTypes';
import { DeleteOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { IAnalysisObjectPscListItem, IAnalysisObjectPscListResponse } from '../AnalysisObjectPscTypes';

export const AnalysisObjectPscList = ({
  data,
  sources,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  onDelete,
  t,
}: AnalysisObjectPscListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IAnalysisObjectPscListItem>();

  const {
    userPermissions: { canGlPscAoDelete },
  } = useStudyPermissions();

  const sourceListOption = getListOption(sources);

  const typeListOption = tableListToOptions(Object.entries(EPscTypeNames).map(([k, v]) => ({ name: v, id: k })));

  const columns: TableColumnsType<IAnalysisObjectPscListItem> = useMemo(
    () =>
      (
        [
          {
            title: t('rootTable.name'),
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            onFilter: undefined,
          },
          {
            title: t('rootTable.type'),
            dataIndex: 'type',
            onFilter: undefined,
            filters: typeListOption,
            render: (type: EPscType) => (type ? EPscTypeNames[type] : 'N/A'),
          },
          {
            title: t('rootTable.libraryVersion'),
            dataIndex: 'version',
            render: (_, record) =>
              Array.isArray(record.library_id) && record.library_id.length ? record.version : '-',
          },
          {
            title: t('rootTable.source'),
            dataIndex: 'source',
            filters: sourceListOption,
            onFilter: undefined,
            render: (_, record) =>
              record.source && (
                <ActorAndInfo
                  info={supportedEnvs[record.env]?.label || t('na')}
                  actor={record.source}
                  actorSize={'sm'}
                />
              ),
          },
          {
            title: t('rootTable.created'),
            dataIndex: 'linked_at',
            sorter: (a, b) => 0,
            sortDirections: ['ascend'],
            render: (linkedAt: number, record) => (
              <ActorAndInfo info={dateToString(linkedAt)} actor={record.created_by} />
            ),
          },
          canGlPscAoDelete && {
            width: 80,
            title: t('delete'),
            dataIndex: 'actionDelete',
            align: 'center',
            render: (_, { version_id, name }) => (
              <Space>
                <Popconfirm
                  title={t('confirmation.title')}
                  description={t('confirmation.description', { name })}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={() => onDelete({ id: version_id, name })}
                >
                  <Button icon={<DeleteOutlined />} loading={loadingDelete} />
                </Popconfirm>
              </Space>
            ),
          },
        ] as TableColumnsType<IAnalysisObjectPscListItem>
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete, sourceListOption, supportedEnvs],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface AnalysisObjectPscListProps {
  data?: IAnalysisObjectPscListResponse;
  sources: string[];
  pagination?: TableProps<IAnalysisObjectPscListItem>['pagination'];
  onChange?: TableProps<IAnalysisObjectPscListItem>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDelete: (val: TLibraryPscDeleteProps) => void;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
