export const getAppConfig = () => ({
  version: '1.0.5',
});

export const configuration = {
  loginURL: '/login-sso',
  logoutURL: '/logout',
  apiUrl: '/',
  defaultInnerSiderWidth: 200,
};

export const CrossStudyId = 0;

export const MandatoryColumns = ['CDR_SKEY', 'MODIFIEDDATETIME'];
export const MandatoryColumnsWithAutogeneration = ['CDR_SKEY'];

export const DataStageTablePrefix = 'DS_';

export const GPDIP_FEATURE_NAME = 'gpdip';
export const DEPLOYMENT_FEATURE_NAME = 'deployment';
export const GL_FEATURE_NAME = 'gl';

export const RCC_EXPORT_OBJ_TYPE_TO_NAME_MAP: Record<string, string> = {
  discrepancy_title: 'Queries',
};
