import { Badge, Popconfirm, Tooltip } from '@ui';
import { selectModelEditorErrors } from '@modules/modelEditor/duck/modelEditorSelectors';
import {
  Connection,
  Controls,
  EdgeChange,
  Node,
  NodeChange,
  useReactFlow,
  ControlButton,
  useStore,
  useStoreApi,
  ReactFlowState,
  Edge,
} from 'reactflow';
import {
  DeleteOutlined,
  ExpandOutlined,
  LockFilled,
  MinusOutlined,
  PartitionOutlined,
  PlusOutlined,
  UnlockFilled,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import { useSelector } from 'react-redux';

export const ControlsDataStage = ({
  setReadOnly,
  onLayout,
  actions,
  loading,
  readOnly,
  canBeUnlocked,
}: ControlsDataStageProps) => {
  const { t } = useTranslation('model');
  const { fitView, zoomIn, zoomOut } = useReactFlow();
  const store = useStoreApi();

  const currentZoom = useStore((s: ReactFlowState) => s.transform[2]);
  const { minZoom, maxZoom } = store.getState();
  const errors = useSelector(selectModelEditorErrors)
    .filter((error) => !error.node?.id)
    .map((error) => t(error.message, error.params ?? {}));
  const uniqueErrors = Array.from(new Set(errors));

  return (
    <Controls position="top-right" showZoom={false} showFitView={false} showInteractive={false}>
      <ControlButton
        css={cssControlBtn}
        onClick={() => zoomIn()}
        title={t('builder.controls.zoomIn')}
        disabled={maxZoom === currentZoom}
      >
        <PlusOutlined />
      </ControlButton>
      <ControlButton
        css={cssControlBtn}
        onClick={() => zoomOut()}
        title={t('builder.controls.zoomOut')}
        disabled={minZoom === currentZoom}
      >
        <MinusOutlined />
      </ControlButton>
      <ControlButton css={cssControlBtn} onClick={() => fitView()} title={t('builder.controls.fitView')}>
        <ExpandOutlined />
      </ControlButton>
      <ControlButton
        css={cssControlBtn}
        onClick={() => setReadOnly(!readOnly)}
        disabled={!canBeUnlocked}
        title={t('builder.controls.interactivity')}
      >
        {readOnly ? <LockFilled /> : <UnlockFilled />}
      </ControlButton>
      {!readOnly && (
        <>
          <ControlButton css={cssControlBtn} onClick={() => onLayout()} title={t('builder.controls.autolayout')}>
            <PartitionOutlined />
          </ControlButton>
          <Popconfirm
            title={t('builder.confirmation.deleteAll.title')}
            description={t('builder.confirmation.deleteAll.description')}
            okText={t('yes')}
            cancelText={t('no')}
            onConfirm={() => actions.onDeleteNodes()}
          >
            <span>
              <ControlButton css={cssControlBtn} title={t('builder.controls.deleteAll')} disabled={loading}>
                <DeleteOutlined />
              </ControlButton>
            </span>
          </Popconfirm>
        </>
      )}
      {errors.length > 0 && (
        <Tooltip
          color="red"
          placement="left"
          title={
            <ul>
              {uniqueErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          }
        >
          <span>
            <ControlButton css={cssControlBtn}>
              <Badge count={uniqueErrors.length} size="small" title="" />
            </ControlButton>
          </span>
        </Tooltip>
      )}
    </Controls>
  );
};

interface Actions {
  onNodesChange: (nodes: NodeChange[]) => void;
  onEdgesChange: (edges: EdgeChange[]) => void;
  addNodes: (node: Node | Node[]) => void;
  setNodes: (nodes: Node[]) => void;
  onConnect: (connection: Connection) => void;
  onDeleteNode: (nodeId: string) => void;
  onDeleteNodes: () => void;
  onHoverEdge: (edge: Edge | null) => void;
}

interface ControlsDataStageProps {
  setReadOnly: (value: boolean) => void;
  onLayout: () => void;
  actions: Actions;
  loading: boolean;
  readOnly?: boolean;
  canBeUnlocked?: boolean;
}

const cssControlBtn = (): CSSObject => ({
  '& > span': {
    width: '100%',
    height: '100%',
  },
  '& > span > svg': {
    minWidth: '100%',
    minHeight: '100%',
  },
});
