import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { ILibraryNotebook, TLibraryNotebookDeleteProps } from '@modules/library/notebook/LibraryNotebookTypes';
import { ELibraryNotebookModalsType } from '@modules/library/notebook/modals';
import { libraryNotebookActions } from '@modules/library/notebook/duck/libraryNotebookSlice';
import {
  useLibraryDeleteNotebookMutation,
  useLibraryNotebookListQuery,
} from '@modules/library/notebook/duck/libraryNotebookApi';
import { LibraryNotebookList } from '@modules/library/notebook/components/LibraryNotebookList';
import { PageTemplateSimple } from '@components';
import { Button, notification } from '@ui';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LibraryNotebookRootPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['libraryNotebook']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const globalLibrary = useSelector(selectGlobalLibrary);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ILibraryNotebook>(initialPage);
  const notebookReportQuery = useLibraryNotebookListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });
  const [deleteNotebook, deleteNotebookQuery] = useLibraryDeleteNotebookMutation();

  const {
    appPermissions: { canGlCloInsert, canGlLibraryUpdate, canGlCloDelete },
  } = useAppPermissions();

  const pagination = getPagination(notebookReportQuery.data?.totalItems);

  const copyNotebook = () =>
    dispatch(libraryNotebookActions.pushModal({ type: ELibraryNotebookModalsType.copyNotebook }));

  const onDelete = async (value: TLibraryNotebookDeleteProps) => {
    const { id, name } = value;
    try {
      await deleteNotebook(id).unwrap();
      notification.success({ message: t('confirmation.successMessage', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: t('confirmation.errorMessage', { name }) });
    }
  };

  const libraryIsEditable = canGlLibraryUpdate && globalLibrary?.status === LibraryStatus.Development;

  return (
    <PageTemplateSimple
      title={{
        children: t('pageRootName'),
        extra: libraryIsEditable && canGlCloInsert && <Button children={t('import')} onClick={copyNotebook} />,
      }}
      content={{
        isLoading: notebookReportQuery.isLoading && !notebookReportQuery.data,
        errorText: t('loadingError'),
        error: notebookReportQuery.error,
      }}
    >
      {!notebookReportQuery.isLoading && (
        <LibraryNotebookList
          data={notebookReportQuery.data}
          libraryId={globalLibrary?.id!}
          onChange={onTableChange}
          onDelete={libraryIsEditable && canGlCloDelete ? onDelete : undefined}
          pagination={pagination}
          loading={notebookReportQuery.isFetching}
          loadingDelete={deleteNotebookQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
