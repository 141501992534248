import { MenuType } from '@routes/appMenu';
import { TAuthAppPermissions, TAuthStudyRolesPermissions } from '@modules/auth/AuthTypes';
import { AppstoreOutlined, GlobalOutlined } from '@ant-design/icons';
import libraryRoutes from './RoutesLibrary';

export const libraryMenu = (libraryId: number, appPermissions: Partial<TAuthAppPermissions>): MenuType => {
  const items = [
    appPermissions.canGlLibraryRead && {
      path: libraryRoutes.view.resolver({ libraryId }),
      key: libraryRoutes.view.id,
      keys: libraryInternalMenu(libraryId, appPermissions).map((data) => data.key),
      title: 'Library',
      icon: <GlobalOutlined />,
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const libraryInternalMenu = (libraryId: number, appPermissions: Partial<TAuthAppPermissions>): MenuType => {
  const items = [
    appPermissions.canGlDmoRead && {
      path: libraryRoutes.models.root.resolver({ libraryId }),
      key: libraryRoutes.models.root.id,
      title: 'Data Models',
    },
    appPermissions.canGlCdroRead && {
      path: libraryRoutes.cdr.root.resolver({ libraryId }),
      key: libraryRoutes.cdr.root.id,
      title: 'CDR Reports',
    },
    appPermissions.canGlSloRead && {
      path: libraryRoutes.sqlLab.root.resolver({ libraryId }),
      key: libraryRoutes.sqlLab.root.id,
      title: 'SQL Lab',
    },
    appPermissions.canGlSqoRead && {
      path: libraryRoutes.sqlQuery.root.resolver({ libraryId }),
      key: libraryRoutes.sqlQuery.root.id,
      title: 'SQL Queries',
    },
    appPermissions.canGlCloRead && {
      path: libraryRoutes.notebook.root.resolver({ libraryId }),
      key: libraryRoutes.notebook.root.id,
      title: 'Python Notebooks',
    },
    appPermissions.canGlPscRead && {
      path: libraryRoutes.psc.root.resolver({ libraryId }),
      key: libraryRoutes.psc.root.id,
      title: 'PSC Filters',
    },
    appPermissions.canGlAuditLogsRead && {
      path: libraryRoutes.audit.root.resolver({ libraryId }),
      key: libraryRoutes.audit.root.id,
      title: 'Audit Log',
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const appStudyLibraryMenu = () => [
  {
    path: libraryRoutes.root.path,
    key: libraryRoutes.root.id,
    title: 'Global Library',
    icon: <GlobalOutlined />,
  },
];

export const appStudyAnalysisPackageMenu = (
  studyId: number,
  userStudyPermissions: Partial<TAuthStudyRolesPermissions>,
) => {
  const items = [
    userStudyPermissions.canGlApRead && {
      path: libraryRoutes.analysisPackage.resolver({ studyId }),
      key: libraryRoutes.analysisPackage.id,
      title: 'Analysis Package Versions',
    },
    userStudyPermissions.canGlApRead && {
      path: libraryRoutes.deploymentPlans.resolver({ studyId }),
      key: libraryRoutes.deploymentPlans.id,
      title: 'Deployment Plans',
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const appStudyAnalysisObjectsMenu = (
  studyId: number,
  userStudyPermissions: Partial<TAuthStudyRolesPermissions>,
) => {
  const items = [
    (userStudyPermissions.canGlDmAoRead || userStudyPermissions.canGlCdrAoRead) && {
      path: userStudyPermissions.canGlDmAoRead
        ? libraryRoutes.analysisObjectsModels.root.resolver({ studyId })
        : libraryRoutes.analysisObjectsCdr.root.resolver({ studyId }),
      key: libraryRoutes.analysisObjects.id,
      keys: studyAnalysisObjectsInternalMenu(studyId, userStudyPermissions).map((data) => data.key),
      title: 'Analysis Objects',
      icon: <AppstoreOutlined />,
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};

export const studyAnalysisObjectsInternalMenu = (
  studyId: number,
  userStudyPermissions: Partial<TAuthStudyRolesPermissions>,
): MenuType => {
  const items = [
    userStudyPermissions.canGlDmAoRead && {
      path: libraryRoutes.analysisObjectsModels.root.resolver({ studyId }),
      key: libraryRoutes.analysisObjectsModels.root.id,
      title: 'Data Models',
    },
    userStudyPermissions.canGlCdrAoRead && {
      path: libraryRoutes.analysisObjectsCdr.root.resolver({ studyId }),
      key: libraryRoutes.analysisObjectsCdr.root.id,
      title: 'CDR Reports',
    },
    userStudyPermissions.canGlSqlAoRead && {
      path: libraryRoutes.analysisObjectsSQLDatasets.root.resolver({ studyId }),
      key: libraryRoutes.analysisObjectsSQLDatasets.root.id,
      title: 'SQL Lab',
    },
    userStudyPermissions.canGlPscAoRead && {
      path: libraryRoutes.analysisObjectsPsc.root.resolver({ studyId }),
      key: libraryRoutes.analysisObjectsPsc.root.id,
      title: 'PSC Filters',
    },
  ];

  return items.filter((item) => typeof item === 'object') as MenuType;
};
