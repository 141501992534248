import { Form, FormItem, FormLayout, Select, Radio, Table, notification } from '@ui';
import {
  ELibraryEntityNames,
  ELibrarySourceType,
  ESelectedActions,
  IBaseLibraryEntitySystemInfo,
} from '@modules/library/root/LibraryTypes';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { useLazyEnvStudyListQuery, useLazyLibraryListQuery } from '@modules/library/root/duck/libraryApi';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { itemsToOptions } from '@shared/utils/Form';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { SupportedEnvs } from '@app/AppTypes';
import { IBaseColumnProps } from '@shared/components/ObjectTable';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { QueryErrorType } from '@shared/utils/Error';
import { Col, Row, TableColumnsType, TablePaginationConfig } from 'antd';
import { CSSObject } from '@emotion/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ILibrarySqlQuery, ISqlQuery } from '../LibrarySqlQueryTypes';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export interface GetLibraryImportSqlQueryFromLibrarySource extends IBaseColumnProps {
  version?: number | string;
  description?: string;
  rawData: ILibrarySqlQuery | ISqlQuery;
}

export const getLibraryImportSqlQueryFromLibrarySource = (
  data: ILibrarySqlQuery[] | ISqlQuery[],
  kind: ELibrarySourceType,
): Array<GetLibraryImportSqlQueryFromLibrarySource> => {
  if (!Array.isArray(data)) return [];

  if (kind === ELibrarySourceType.Library) {
    return (data as ILibrarySqlQuery[]).map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      version: item.version,
      rawData: item,
    }));
  }

  return (data as ILibrarySqlQuery[]).map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
    rawData: item,
  }));
};

export const LibraryImportSqlQuery = ({
  onClose,
  columns,
  sourceOnlyLibrary,
  locale,
  kind,
  libraryTableListQuery,
  studyTableListQuery,
  tableDataFetching,
  libraryStatuses,
  hideOverwriteHandles,
  onImport,
}: ILibraryImportSqlQueryProps) => {
  const globalLibrary = useSelector(selectGlobalLibrary);
  const user = useSelector(selectAuthUser);
  const [form] = Form.useForm();
  const { t } = useTranslation(['libraryRoot']);
  const { setPagination, getPagination } = useTablePaginationState(initialPage);

  const [sourceData, setSourceData] = useState<TSourceDataProps>([]);
  const [selectedFilter, setSelectedFilter] = useState<ESelectedActions>(ESelectedActions.SHOW_ALL);
  const [libraryListQuery, libraryListQueryData] = useLazyLibraryListQuery();
  const [envStudyListQuery, studiesListQueryData] = useLazyEnvStudyListQuery();

  const [tableData, setTableData] = useState<ILibraryImportSqlQuery[]>([]);
  const [selectedTableItems, setSelectedTableItems] = useState<React.Key[]>([]);

  const fetchingNames = libraryListQueryData.isFetching || studiesListQueryData.isFetching;

  const pagination = getPagination(tableData.length);

  const sourceType = useMemo(() => {
    const sourceOptions = [
      {
        label: ELibrarySourceType.Library,
        value: ELibrarySourceType.Library,
      },
    ];

    if (!sourceOnlyLibrary) {
      sourceOptions.push(
        ...Object.entries(user?.environments || ({} as SupportedEnvs)).map(([env, envData]) => {
          return { label: `Study (${envData!.label})`, value: env } as any;
        }),
      );
    }
    return sourceOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.environments]);

  const initValues = {
    sourceKind: ELibrarySourceType.Library,
    overwrite: false,
  };

  const onChangeSource = useCallback(
    async (value: string) => {
      setTableData([]);
      setSelectedTableItems([]);
      setSourceData([]);
      form.setFieldValue('sourceId', '');
      if (value === ELibrarySourceType.Library) {
        const data = await libraryListQuery({
          status: libraryStatuses ? libraryStatuses.join(',') : undefined,
          page_size: 999,
        }).unwrap();
        const libraryListOption = itemsToOptions(data?.items?.filter((item) => item.id !== globalLibrary?.id));
        setSourceData(libraryListOption);
      } else if (value) {
        const data = await envStudyListQuery({ env: value }).unwrap();
        const studiesListOption = itemsToOptions(data);
        setSourceData(studiesListOption);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [libraryListQuery, envStudyListQuery],
  );

  useEffect(() => {
    onChangeSource(ELibrarySourceType.Library);
  }, [onChangeSource]);

  const onChangeSourceId = async (id: number) => {
    try {
      const source = form.getFieldValue('sourceKind');
      if (source === ELibrarySourceType.Library) {
        if (libraryTableListQuery) {
          const data = await libraryTableListQuery(id);
          setTableData(data);
        }
      } else if (source) {
        if (studyTableListQuery) {
          const data = await studyTableListQuery(id, source);
          setTableData(data);
        }
      }
    } catch (e) {
      console.error('Error while changing source id', e);
    }
  };

  const onSubmit = async (values: ILibraryCopyFieldFormValues) => {
    try {
      let options: IOnImportOptions = { overwrite: values.overwrite, kind: ELibrarySourceType.Library };

      if (values['sourceKind'] && values['sourceKind'] !== ELibrarySourceType.Library) {
        const studyName = sourceData.find((item) => item.value === values['sourceId'])?.label!;

        options = {
          ...options,
          kind: ELibrarySourceType.Study,
          systemInfo: {
            env: values['sourceKind'],
            tenant_info: user!.tenant_info,
            source: studyName,
          },
        };
      }

      await onImport(
        tableData.filter((item) => selectedTableItems.includes(item.id)),
        options,
      );
      notification.success({ message: t('copyForm.successMessage', { kind }) });
      onClose();
    } catch (e: any) {
      console.error('Error while importing', e);
      if ((e as QueryErrorType)?.data?.userMsg) {
        notification.error({ message: (e as QueryErrorType)?.data?.userMsg });
      }
    }
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => {
    setPagination(tablePagination.current!);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ILibraryImportSqlQuery[]) => {
      setSelectedTableItems(selectedRowKeys);
    },
  };

  const filteredSqlQuerys = useMemo(() => {
    if (selectedFilter === ESelectedActions.HIDE_SELECTED) {
      return tableData?.filter((model) => !selectedTableItems.includes(model.id));
    }
    return tableData;
  }, [selectedFilter, tableData, selectedTableItems]);

  return (
    <FormLayout
      form={form}
      layout="vertical"
      onCancel={onClose}
      onSubmit={onSubmit}
      okText={t('save')}
      initialValues={initValues}
      submitIsDisabled={selectedTableItems.length === 0}
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceKind"
            label={t('copyForm.sourceKind')}
            rules={[{ required: true }]}
          >
            <Select placeholder={t('copyForm.sourceKindPlaceholder')} options={sourceType} onChange={onChangeSource} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="sourceId"
            label={t('copyForm.sourceId')}
            rules={[{ required: true }]}
            dependencies={['source']}
          >
            <Select
              placeholder={t('copyForm.sourceIdPlaceholder')}
              options={sourceData}
              loading={fetchingNames}
              disabled={fetchingNames}
              onChange={onChangeSourceId}
            />
          </FormItem>
        </Col>
      </Row>
      {!hideOverwriteHandles && (
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="overwrite"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              label={t('copyForm.duplicateLabel.label')}
              css={cssHorizontalFormItem}
            >
              <Radio.Group>
                <Radio value={false}>{t('copyForm.duplicateLabel.createCopy')}</Radio>
                <Radio value={true}>{t('copyForm.duplicateLabel.useExisting')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="table" wrapperCol={{ span: 24 }}>
            <Table
              size="small"
              bordered
              columns={columns}
              locale={locale}
              loading={tableDataFetching}
              rowKey="id"
              dataSource={filteredSqlQuerys}
              pagination={pagination}
              onChange={onTableChange}
              rowSelection={{
                preserveSelectedRowKeys: true,
                type: 'checkbox',
                ...rowSelection,
                selectedRowKeys: selectedTableItems,
                selections: [
                  Table.SELECTION_INVERT,
                  {
                    key: ESelectedActions.SHOW_ALL,
                    text: 'Show all',
                    onSelect: () => setSelectedFilter(ESelectedActions.SHOW_ALL),
                  },
                  {
                    key: ESelectedActions.HIDE_SELECTED,
                    text: 'Hide selected',
                    onSelect: () => setSelectedFilter(ESelectedActions.HIDE_SELECTED),
                  },
                ],
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormLayout>
  );
};

const cssHorizontalFormItem = (): CSSObject => ({
  '&& .ant-form-item-row': {
    flexDirection: 'row',
    alignItems: 'center',
  },
  '&& .ant-form-item-label': {
    paddingBottom: 0,
  },
});

export interface ILibraryImportSqlQueryProps {
  onClose: () => void;
  columns: TableColumnsType<ILibraryImportSqlQuery>;
  sourceOnlyLibrary?: boolean;
  locale: Record<string, any>;
  kind: ELibraryEntityNames;
  libraryTableListQuery?: WrapperFn;
  studyTableListQuery?: WrapperFn;
  tableDataFetching: boolean;
  onImport: (data: ILibraryImportSqlQuery[], options: IOnImportOptions) => void;
  libraryStatuses?: LibraryStatus[];
  hideOverwriteHandles?: boolean;
}

type TSourceDataProps = {
  label: string;
  value: number;
}[];

interface IOnImportOptions {
  overwrite: boolean;
  kind: ELibrarySourceType;
  systemInfo?: IBaseLibraryEntitySystemInfo;
}

export type ILibraryImportSqlQuery = GetLibraryImportSqlQueryFromLibrarySource;

export type WrapperFn = (id: number, env?: string) => Promise<ILibraryImportSqlQuery[]>;

interface ILibraryCopyFieldFormValues {
  sourceKind: ELibrarySourceType;
  sourceId: number;
  overwrite: boolean;
}
