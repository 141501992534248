import { DraggableModal, notification, Typography } from '@ui';
import { ELibraryEntityNames, ELibrarySourceType } from '@modules/library/root/LibraryTypes';
import {
  useLazyLibraryNotebookListQuery,
  useLibraryCopyNotebookMutation,
  useValidateNotebookMutation,
} from '@modules/library/notebook/duck/libraryNotebookApi';
import { prepareLibraryNotebook } from '@modules/library/notebook/duck/libraryNotebookUtils';
import { useNotebookColumns } from '@modules/library/notebook/duck/libraryNotebookHooks';
import { LibraryStatus } from '@modules/library/root/duck/libraryConstants';
import { ConfirmModal, useConfirmModal } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { isArray } from 'lodash';
import {
  getLibraryImportNotebookFromLibrarySource,
  ILibraryImportNotebookProps,
  LibraryImportNotebook,
  WrapperFn,
} from '../../components/LibraryImportNotebook';

const LibraryNotebookModalsCopyNotebookContent = ({ t, onClose }: LibraryNotebookModalsCopyNotebookContentProps) => {
  const { notebookColumns, locale } = useNotebookColumns();
  const [libraryNotebookListQuery, libraryNotebookListQueryData] = useLazyLibraryNotebookListQuery();
  const [copyNotebook] = useLibraryCopyNotebookMutation();
  const [validateNotebook] = useValidateNotebookMutation();
  const confirmModal = useConfirmModal();

  const wrapperLibrary: WrapperFn = async (id: number) => {
    const data = await libraryNotebookListQuery({ library_id: id, detailed: '1' }).unwrap();
    return getLibraryImportNotebookFromLibrarySource(data.items, ELibrarySourceType.Library);
  };

  const onImport: ILibraryImportNotebookProps['onImport'] = async (values, { kind, overwrite, systemInfo }) => {
    if (overwrite) {
      try {
        await validateNotebook({ python_notebook_names: values.map((item) => item.name) }).unwrap();

        await copyNotebook({ data: prepareLibraryNotebook(values, kind, systemInfo), overwrite: overwrite }).unwrap();
      } catch (error) {
        const { data } = error as QueryErrorType;
        const isError =
          data.rawData?.message || typeof data.rawData?.error === 'string' || isArray(data.rawData?.error);

        confirmModal.openConfirmation({
          content: isError ? (
            <Typography.Text type="danger">{data.rawData?.message || data.rawData?.error}</Typography.Text>
          ) : (
            <div>
              {t('confirmOverwrite.content')}
              <strong>{Object.keys(data.rawData?.error || []).join(', ')}</strong>
            </div>
          ),
          data: prepareLibraryNotebook(values, kind, systemInfo),
        });
        // TODO Refactor this stuff later
        // eslint-disable-next-line no-throw-literal
        throw '';
      }
    } else {
      await copyNotebook({ data: prepareLibraryNotebook(values, kind, systemInfo), overwrite: overwrite }).unwrap();
    }
  };

  const onOverwriteConfirm = async (data: any) => {
    try {
      await copyNotebook({ data, overwrite: true }).unwrap();
      onClose();
    } catch (error) {
      notification.error({ message: (error as QueryErrorType).data.userMsg });
    }
  };

  return (
    <>
      <LibraryImportNotebook
        onClose={onClose}
        columns={notebookColumns}
        locale={locale}
        kind={ELibraryEntityNames.Notebook}
        libraryTableListQuery={wrapperLibrary}
        tableDataFetching={libraryNotebookListQueryData.isFetching}
        onImport={onImport}
        libraryStatuses={[LibraryStatus.Active, LibraryStatus.Development]}
        sourceOnlyLibrary
        hideOverwriteHandles
      />
      <ConfirmModal title={t('confirmOverwrite.content')} submitFunc={onOverwriteConfirm} {...confirmModal} />
    </>
  );
};

export const LibraryNotebookModalsCopyNotebook = ({ open, data, onClose }: ILibraryNotebookModalsCopyNotebookProps) => {
  const { t } = useTranslation(['libraryNotebook']);

  return (
    <DraggableModal width="50%" open={open} onCancel={onClose} title={t('copyForm.title')} footer={null} destroyOnClose>
      {open && <LibraryNotebookModalsCopyNotebookContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

export interface ILibraryNotebookModalsCopyNotebookProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface LibraryNotebookModalsCopyNotebookContentProps
  extends Pick<ILibraryNotebookModalsCopyNotebookProps, 'data' | 'onClose'> {
  t: TFunction;
}
