import { IAnalysisObjectSQLLabModalsImportProps } from './modals/AnalysisObjectSQLLabModalsImport';

export enum EAnalysisObjectModalsType {
  'importSQLDataset' = 'importSQLDataset',
}

export type TAnalysisObjectModalsPushModalPayload = {
  type: EAnalysisObjectModalsType.importSQLDataset;
  data?: IAnalysisObjectSQLLabModalsImportProps['data'];
};

export type TAnalysisObjectModalsState = {
  selectedModal: EAnalysisObjectModalsType | null;
  data?: IAnalysisObjectSQLLabModalsImportProps['data'];
};
