import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo } from '@components';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { getListOption } from '@shared/utils/Table';
import { TLibraryCdrDeleteProps } from '@modules/library/cdr/LibraryCdrTypes';
import { Button, Popconfirm, Space, Table } from '@ui';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { ECdrType } from '@modules/library/cdr/LibraryCdrTypes';
import { getReportVersionLabel } from '@shared/utils/common';
import { SupportedEnvs } from '@app/AppTypes';
import { useMemo } from 'react';
import { TableColumnsType, TableProps } from 'antd';
import { TFunction } from 'i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { IAnalysisObjectCDRListItem, IAnalysisObjectCDRListResponse } from '../AnalysisObjectCDRTypes';

export const AnalysisObjectCDRList = ({
  data,
  sources,
  loading,
  pagination,
  onChange,
  loadingDelete,
  supportedEnvs,
  onDelete,
  t,
}: AnalysisObjectCDRListProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<IAnalysisObjectCDRListItem>();

  const {
    userPermissions: { canGlCdrAoDelete },
  } = useStudyPermissions();

  const sourceListOption = getListOption(sources);

  const columns: TableColumnsType<IAnalysisObjectCDRListItem> = useMemo(
    () =>
      (
        [
          {
            title: t('rootTable.name'),
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
            onFilter: undefined,
          },
          {
            title: t('rootTable.cdrType'),
            dataIndex: 'object_type',
            filters: Object.entries(ECdrType).map(([key, value]) => ({ text: value, value: key })),
            onFilter: undefined,
            render: (_, record) => ECdrType[record.object_type],
          },
          {
            title: t('rootTable.sourceVersion'),
            render: (_, record) => getReportVersionLabel(record?.configuration?.version_label),
          },
          {
            title: t('rootTable.libraryVersion'),
            dataIndex: 'version',
            render: (_, record) =>
              Array.isArray(record.library_id) && record.library_id.length ? record.version : '-',
          },
          {
            title: t('rootTable.source'),
            dataIndex: 'source',
            filters: sourceListOption,
            onFilter: undefined,
            render: (_, record) =>
              record.source && (
                <ActorAndInfo
                  info={supportedEnvs[record.env]?.label || t('na')}
                  actor={record.source}
                  actorSize={'sm'}
                />
              ),
          },
          {
            title: t('rootTable.created'),
            dataIndex: 'linked_at',
            sorter: (a, b) => 0,
            sortDirections: ['ascend'],
            render: (linkedAt: number, record) => (
              <ActorAndInfo info={dateToString(linkedAt)} actor={record.created_by} />
            ),
          },
          canGlCdrAoDelete && {
            width: 80,
            title: t('delete'),
            dataIndex: 'actionDelete',
            align: 'center',
            render: (_, record) => (
              <Space>
                <Popconfirm
                  title={t('confirmation.title')}
                  description={t('confirmation.description')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={() => onDelete({ id: record.version_id, name: record.name })}
                >
                  <Button icon={<DeleteOutlined />} loading={loadingDelete} />
                </Popconfirm>
              </Space>
            ),
          },
        ] as TableColumnsType<IAnalysisObjectCDRListItem>
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDelete, sourceListOption, supportedEnvs],
  );

  return (
    <Table
      bordered
      locale={locale}
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

export interface AnalysisObjectCDRListProps {
  data?: IAnalysisObjectCDRListResponse;
  sources: string[];
  pagination?: TableProps<IAnalysisObjectCDRListItem>['pagination'];
  onChange?: TableProps<IAnalysisObjectCDRListItem>['onChange'];
  loading?: boolean;
  loadingDelete?: boolean;
  onDelete: (val: TLibraryCdrDeleteProps) => void;
  supportedEnvs: SupportedEnvs;
  t: TFunction;
}
