import { CrossTableListSider, ViewerDataTable } from '@shared/components';
import { Typography } from '@ui';
import { TableListSider as StudyTableListSider } from '@shared/components';
import { VerticalSplit } from '@components';
import { EditorModelPane } from '@modules/modelEditor/containers';
import { selectModelDataViewer } from '@modules/model/duck/modelSelectors';
import { modelActions } from '@modules/model/duck/modelSlice';
import { ViewerDataTableRef } from '@shared/components/ViewerDataTable/ViewerDataTableTypes';
import { AppGatewaySection, FallbackRender } from '@app/components';
import { AppToolbarPortal } from '@app/components/Header/AppToolbarPortal';
import { DataViewerEnvSelector } from '@modules/viewer/components/DataViewerEnvSelector';
import { useEnvironments } from '@modules/viewer/duck/viewerHooks';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { isCrossStudy } from '@shared/utils/common';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { CSSObject } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useRef } from 'react';

const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export const ModelConfigurationPage = () => {
  const dispatch = useDispatch();
  const { modelId, sourceLabel } = useParams();
  const globalStudy = useSelector(selectGlobalStudy);
  const childRef = useRef<ViewerDataTableRef | null>(null);
  const {
    userPermissions: { canDataModelRtSwitch },
  } = useStudyPermissions();
  const {
    appPermissions: { canCrossDataModelRtSwitch },
  } = useAppPermissions();
  const { currentAppEnv, checkExternalSource } = useEnvironments(false);
  const { tableKey, tableName, opened, isReview, sourceEnv } = useSelector(selectModelDataViewer);

  const modelSourceEnv = checkExternalSource({ targetLabel: sourceLabel });

  const selectDataViewerTable = useCallback((tableKey: string, tableName: string) => {
    dispatch(modelActions.setDataViewer({ tableKey, tableName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnCollapse = useCallback(() => dispatch(modelActions.closeDataViewer()), [dispatch]);

  const handleRefetch = useCallback(() => {
    childRef.current && childRef.current.refetch();
  }, []);

  const TableListSider = isCrossStudy(globalStudy?.id) ? CrossTableListSider : StudyTableListSider;

  if (!modelId) {
    return <Typography.Title type="secondary" children="There is no selected Model" />;
  }

  return (
    <div css={cssBody}>
      <AppGatewaySection onlyGLMode onlyStudy>
        {(canDataModelRtSwitch || (canCrossDataModelRtSwitch && isCrossStudy(globalStudy?.id))) && (
          <AppToolbarPortal>
            <DataViewerEnvSelector />
          </AppToolbarPortal>
        )}
      </AppGatewaySection>
      <TableListSider
        selectedTable={tableKey}
        onSelectTable={selectDataViewerTable}
        refetch={handleRefetch}
        draggable={modelSourceEnv.isExternalSource === false && currentAppEnv?.current === true}
      />
      <VerticalSplit
        top={
          <ErrorBoundary FallbackComponent={FallbackRender}>
            <EditorModelPane
              modelId={+modelId}
              currentAppEnv={currentAppEnv?.env}
              isExternalModel={modelSourceEnv.isExternalSource}
              modelSourceEnv={modelSourceEnv.targetSourceEnv}
              modelSourceEnvData={modelSourceEnv.targetSourceEnvData}
            />
          </ErrorBoundary>
        }
        bottom={
          <div css={cssContent}>
            {tableName && tableKey ? (
              // TODO: change when "DNA-1676-Uploading the same table to different stores" task will be done
              <ErrorBoundary FallbackComponent={FallbackRender}>
                <ViewerDataTable
                  ref={childRef}
                  tableId={tableKey}
                  tableName={tableName}
                  refetch={handleRefetch}
                  initialPage={initialPage}
                  showShortInfo
                  hideFilter={!isReview}
                  showPagination={isReview}
                  sourceEnv={sourceEnv}
                  isDataModel
                />
              </ErrorBoundary>
            ) : (
              <div css={cssEmptyBox}>
                <Typography.Title type="secondary" children="There is no selected Table" />
              </div>
            )}
          </div>
        }
        collapsed={!opened}
        onCollapse={handleOnCollapse}
      />
    </div>
  );
};

const cssBody = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
});

const cssContent = (): CSSObject => ({
  minWidth: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflowX: 'hidden',
  overflowY: 'hidden',
});

const cssEmptyBox = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
