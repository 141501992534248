import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { Button } from '@ui';
import { initialPage } from '@shared/utils/common';
import { PageTemplateSimple } from '@components';
import { QueryErrorType } from '@shared/utils/Error';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { ISQLLabObject } from '@modules/dnaObject/SQLLab/SQLLabObjectTypes';
import { SQLLabObjectList } from '@modules/dnaObject/SQLLab/components/SQLLabObjectList';
import { useAppContext } from '@app/AppContext';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useAnalysisObjectSQLDatasetListQuery,
  useDeleteAnalysisObjectSQLDatasetMutation,
} from '../duck/analysisObjectApi';
import { analysisObjectActions } from '../duck/analysisObjectSlice';
import { EAnalysisObjectModalsType } from '../AnalysisObjectTypes';

export const AnalysisObjectSQLLabRootPage = () => {
  const { notificationApi } = useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation(['analysisObjects']);
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<ISQLLabObject>(initialPage);
  const {
    userPermissions: { canGlSqlAoImport, canGlSqlAoDelete },
  } = useStudyPermissions();

  const sqlDatasetQuery = useAnalysisObjectSQLDatasetListQuery({
    detailed: '0',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });

  const [deleteSQLDataset, deleteSQLDatasetQuery] = useDeleteAnalysisObjectSQLDatasetMutation();

  const pagination = getPagination(sqlDatasetQuery.data?.totalItems);

  const importSQLLabAOModal = () =>
    dispatch(analysisObjectActions.pushModal({ type: EAnalysisObjectModalsType.importSQLDataset }));

  const onDeleteSQLDataset = async (value: { id: number; name: string }) => {
    const { id, name } = value;
    try {
      await deleteSQLDataset(id).unwrap();
      notificationApi.success({ message: t('sqlLab.modals.deleteSuccess', { name }) });
    } catch (e) {
      notificationApi.error({ message: (e as QueryErrorType).data.userMsg });
    }
  };

  return (
    <PageTemplateSimple
      content={{
        isLoading: sqlDatasetQuery.isLoading && !sqlDatasetQuery.data,
        errorText: t('sqlLab.loadingListError'),
        error: sqlDatasetQuery.error,
      }}
      title={{
        children: t('sqlLab.rootPageName'),
        pageTitle: t('sqlLab.pageTitle'),
        extra: canGlSqlAoImport && <Button children={t('import')} onClick={importSQLLabAOModal} />,
      }}
    >
      {sqlDatasetQuery.data && (
        <SQLLabObjectList
          data={sqlDatasetQuery.data}
          onChange={onTableChange}
          pagination={pagination}
          loading={sqlDatasetQuery.isFetching}
          onDelete={canGlSqlAoDelete ? onDeleteSQLDataset : undefined}
          loadingDelete={deleteSQLDatasetQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
